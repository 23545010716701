<template>
<div class="donor">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="logo text-center">
                    <img style="width:100%; display:inline-block; text-align:center" src="../../assets/donation.png" alt="logo">
                </div>

                <div class="donor-body">
                    <form action="#" method="post">
                        <div class="form-group">
                            الاسم رباعي：
                            <Input placeholder="الاسم رباعي" style="width: 50%" />
                        </div>
                        <div class="form-group">
                            رقم الهاتف：
                            <Input type="number" placeholder="رقم الهاتف" style="width: 50%" />
                        </div>
                        <div class="form-group">
                            النوع : 
                            <RadioGroup>
                                <Radio label="ذكر"></Radio>
                                <Radio label="انثى"></Radio>
                            </RadioGroup>
                        </div>
                        <div class="form-group">
                            فصيلة الدم :
                            <Select size="small" style="width:50%" placeholder="فصيلة الدم">
                                <Option>b+</Option>
                                <Option>a+</Option>
                                <Option>c+</Option>
                                <Option>d+</Option>
                                <Option>w+</Option>
                            </Select>
                        </div>
                        <div class="form-group">
                            الولاية :
                            <Select size="small" style="width:50%" placeholder="الولاية">
                                <Option>الخرطوم</Option>
                                <Option>الشمالية</Option>
                                <Option>جنوب كردفان</Option>
                                <Option>الفاشر</Option>
                            </Select>
                        </div>
                        <Button icon="md-add" type="primary">تسجبل</Button>
                    </form>
                </div>
                <div class="cases-body text-right">
                </div>
                <div class="col-md-4">

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: '',
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" >
    $secound_color : #F0F6F7FF;
    $main_color : #451e3e;
    body {
        direction: rtl;

        .donor-body {
            border: 1px solid $main_color;
            margin: 5% 0 ;
            padding: 20px;
            text-align: right;
            border-radius: 20px;
        }
    }
</style>
